import React from 'react'
import Layout from '../components/layout'
import { StaticQuery, graphql } from 'gatsby'
import { fluidImage } from '../fragments'
import Img from 'gatsby-image'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
const About = () => (
  <StaticQuery
    query={graphql`
      query AboutQuery {
        oldMe: file(
          relativePath: { eq: "tobias-meixner-frueh-uebt-sich-2.jpg" }
        ) {
          ...fluidImage
        }
        newMe: file(
          relativePath: { eq: "tobias-meixner-at-infinity-it-success.jpg" }
        ) {
          ...fluidImage
        }
      }
    `}
    render={(data) => (
      <Layout>
        <Helmet>
          <title>About Tobias Meixner</title>
        </Helmet>
        <Img
          fluid={data.oldMe.childImageSharp.fluid}
          style={{
            display: 'inline-block',
            width: '50%',
            height: '185px',
            objectFit: 'cover',
          }}
          alt="Tobias old days"
        />
        <Img
          fluid={data.newMe.childImageSharp.fluid}
          style={{
            display: 'inline-block',
            width: '50%',
            height: '185px',
            objectFit: 'cover',
          }}
          alt="Tobias new days"
        />
        <div className={'quote'}>
          Everything that irritates us about others can lead us to an
          understanding of ourselves.
          <span>C.G. Jung</span>
        </div>
        <h1
          style={{
            marginTop: '1rem',
          }}
        >
          About
        </h1>
        <p>
          Born and raised in Berlin, Germany.
          <br />
          Growing up being a professional ice speed skater with mediocre
          success.
        </p>
        <p>
          Later found my true passion in technology, specifically building
          software which resulted in studying Information Systems at Berlin
          School of Economics and Law in cooperation with software vendor
          SAPERION AG (corporate studies).
        </p>
        <p>
          After graduation in 2012 I moved to Asia with constantly expanding
          responsibilities in software development as project manager and
          development manager.
        </p>
        <p>
          Following 6 years in Bangkok, Thailand I have moved to China living
          for 3 years in Guangzhou and Shenzhen. Returned to Thailand in 2020.
        </p>
        <p>
          From 2016 until 2022 I have been driving the team and technology at
          Brikl where we build the next-generation of e-commerce storefronts
          (microstores) and before a 3D design studio.
        </p>
        <p>
          As Co-founder and CTO most of my energy and attention has been going
          into building a great team at Brikl with proper engineering culture,
          lasting technology and solving customer problems.
        </p>
        <p>
          In August 2022 I have stepped down as CTO at Brikl and remain a
          cordial supporter of Brikl as Co-Founder and Advisor.
        </p>

        <p>
          Shortly after, in September 2022 then shifted focus on Hubql to solve
          pain points in the software development process such as data modeling,
          API integrations and more.
        </p>
        <p>
          The past years I started to give back to the community by organising
          regular GraphQL meetups in Bangkok and helping to organise the GraphQL
          Asia conference.
        </p>
        <p>
          As another form of giving back and sharing my experience I am also
          available as freelancer, architect, consultant, technical advisor and
          angel investor or speaker at events. Feel free to contact me{' '}
          <Link
            style={{
              textDecoration: 'underline',
            }}
            to={'/contact'}
          >
            here
          </Link>
          .
        </p>
        <h2>Personality</h2>
        <ul className="personality">
          <li>
            <strong>Driven by passion</strong>
            <span>my engine to success</span>
          </li>
          <li>
            <strong>INFP</strong>
            <span>following MBTI assessment</span>
          </li>
          <li>
            <strong>Individualistic</strong>
            <span>thinking outside the box</span>
          </li>
          <li>
            <strong>Positive thinker</strong>
            <span>key to be persistant</span>
          </li>
          <li>
            <strong>Service-oriented</strong>
            <span>giving rather than taking</span>
          </li>
          <li>
            <strong>Creative</strong>
            <span>having constant ideas</span>
          </li>
          <li>
            <strong>Loyal</strong>
            <span>unconditionally long-term-oriented</span>
          </li>
          <li>
            <strong>Hard-working</strong>
            <span>dedicated to achieve results</span>
          </li>
          <li>
            <strong>Inspirational</strong>
            <span>enjoying to engage or teach</span>
          </li>
        </ul>
      </Layout>
    )}
  />
)

export default About
